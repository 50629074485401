






































































































































































import { mapActions, mapGetters } from 'vuex'
import { PageType } from '@/calendesk/models/BuilderTypes'
import Page from '@/calendesk/models/DTO/Response/Page'
import { errorNotification } from '@/lib/calendesk-js-library/tools/notification'
import slugify from 'slugify'
import { plainToClass } from 'class-transformer'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/builder/sections/mixins/DraftElement'
import { maxChars, required } from '@/lib/calendesk-js-library/forms/validators'
import { cloneObject } from '@/lib/calendesk-js-library/tools/helpers'

export default mixins(DraftElement).extend({
  name: 'CreatePageDialog',
  data () {
    return {
      loader: false,
      pageTypes: PageType,
      routeSlug: null as string | null,
      type: PageType.PAGE as PageType,
      name: '',
      description: '',
      action: null as string | null,
      authOnly: false,
      openInNewTab: false,
      editMode: false,
      rules: {
        required,
        maxChars
      }
    }
  },
  computed: {
    route: {
      get (): string | null {
        return this.routeSlug
      },
      set (value: string): void {
        this.routeSlug = slugify(value, { lower: true })
      }
    },
    ...mapGetters({
      dialog: 'dialog/getInstance',
      domain: 'domain/getDefaultDomain'
    }),
    pageListType () {
      if (this.editMode && this.type === PageType.MAIN_PAGE) {
        return [
          PageType.MAIN_PAGE
        ]
      }

      return [
        PageType.PAGE,
        PageType.REDIRECT,
        PageType.BLOG_HANDY
      ]
    }
  },
  watch: {
    type () {
      if (this.type === PageType.PAGE) {
        this.action = null
      }
    },
    name (val) {
      this.route = val
    }
  },
  mounted () {
    if (this.dialog.data && Object.keys(this.dialog.data).length) {
      const page: Page = this.dialog.data
      this.editMode = true
      this.name = page.name
      this.routeSlug = page.route
      this.type = page.type
      this.action = page.action
      this.authOnly = page.authOnly
      this.openInNewTab = page.configuration ? !!page.configuration.open_in_new_tab : false
      this.description = page.configuration && page.configuration.description ? page.configuration.description : null
    }
  },
  methods: {
    ...mapActions({
      closeDialog: 'dialog/close',
      createPage: 'builder/createPage',
      updatePage: 'builder/updatePage'
    }),
    salvePageHandle () {
      if (!(this.$refs.createPageForm as any).validate()) {
        return
      }

      let request: Promise<any> | undefined

      const configuration = this.editMode && this.dialog.data.configuration ? cloneObject(this.dialog.data.configuration) : {}
      configuration.open_in_new_tab = this.openInNewTab
      configuration.description = this.description

      const data = {
        id: this.editMode ? this.dialog.data.id : null,
        name: this.name,
        type: this.type,
        route: this.route,
        action: this.action,
        configuration: configuration,
        sections: this.editMode ? this.dialog.data.sections : [],
        auth_only: this.authOnly
      }

      const page = plainToClass(Page, data)
      if (this.editMode) {
        request = this.updatePage(page)
      } else {
        request = this.createPage(page)
      }

      if (request) {
        this.loader = true
        request
          .then(() => {
            this.closeDialog()
          })
          .catch((error) => {
            errorNotification('operation_failed', error)
          })
          .finally(() => {
            this.loader = false
          })
      }
    }
  }
})
